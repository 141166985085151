<template>
  <div class="pb-9">
    <v-container>
      <v-layout wrap>
        <v-flex
          sm12
          md6
          offset-md3
        >
          <v-card
            elevation="4"
            light
            tag="section"
          >
            <v-card-title>
              <v-layout
                wrap
                justify-center
              >
                <h3 class="headline">
                  Interface Logs
                </h3>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-label>
                <ul>
                  <li class="mb-2">
                    Enter Date Range and View Logs
                  </li>
                  <li class="mb-4">
                    Logs are accessible after 24 hours
                  </li>
                </ul>

                <br />
              </v-label>
              <v-form>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="startDate"
                      outline
                      label="Start Date"
                      type="date"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="endDate"
                      outline
                      label="End Date"
                      type="date"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="sendToApi"
                >
                  Search
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          sm12
          md6
          offset-md3
        >
          <v-layout
            align-center
            justify-space-between
          >
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container>
      <v-data-table
        :headers="iLogsColumns"
        :items="iLogList"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :loading="loadingList"
        item-key="Id"
        show-expand
        class="elevation-1"
        @click:row="expand"
      >
        <template
          v-slot:top
        >
          <v-toolbar
            :elevation="8"
            color="primary"
            dark
            dense
            shaped
          >
            <v-toolbar-title>
              <strong>Logs Found</strong>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import useActivityLogsService from '@/store/useActivityLogsService'

export default {
  setup() {
    const { loadingList, fetchiLogs, iLogList, iLogsColumns } = useActivityLogsService()

    return {
      fetchiLogs,
      loadingList,
      iLogList,
      iLogsColumns,
    }
  },
  data() {
    return {
      startDate: null,
      endDate: null,

      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
      expanded: [],
      singleExpand: false,
    }
  },
  methods: {
    GetLogsInDateRange() {
      const GetByDateDTO = {
        startDate: this.startDate,
        endDate: this.endDate,
      }

      this.fetchiLogs(GetByDateDTO)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn,
.v-card {
  border-radius: 4px;
  color: black;
}

.v-card__title {
  text-transform: uppercase;
}
</style>
