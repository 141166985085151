import store from '@/store'
import { ref } from '@vue/composition-api'

export default function useActivityLogsService() {
  const errorMessages = ref([])

  const loadingList = ref(false)

  const iLogsColumns = [
    {
      text: 'Vendor',
      value: 'Vendor',
    },
    {
      text: 'Error',
      value: 'SentCount',
    },
    {
      text: 'Successful?',
      value: 'WasSuccessful',
    },
    {
      text: 'Attempts',
      value: 'AttemptCount',
    },
    {
      text: 'Sent',
      value: 'SentCount',
    },
    {
      text: 'Received',
      value: 'ReceivedCount',
    },
    {
      text: 'Start Time',
      value: 'BeginConnection',
    },
    {
      text: 'End Time',
      value: 'EndConnection',
    },
  ]

  const IAMColumns = [
    {
      text: 'RECORDED DATE',
      align: 'left',
      sortable: true,
      value: 'recordedDate',
    },
    {
      text: 'Accessed?',
      value: 'whatWasAccessed',
      sortable: true,
    },
    {
      text: 'User Id',
      value: 'userId',
      sortable: true,
    },
    {
      text: 'Site',
      value: 'siteId',
      sortable: true,
    },
    {
      text: '',
      value: 'data-table-expand',
    },
  ]

  const aLogColumns = [
    {
      text: 'RECORDED DATE',
      align: 'left',
      sortable: true,
      value: 'recordedDate',
    },
    {
      text: 'STATEMENT',
      value: 'changeCompleted',
      sortable: true,
    },
    {
      text: 'STAFF MEMBER',
      value: 'usersName',
      sortable: true,
    },
    {
      text: 'AFFECTED RECORD',
      value: 'modelType',
      sortable: true,
    },
    {
      text: '',
      value: 'data-table-expand',
    },
  ]

  const IAMLogList = ref([])
  const activityLogList = ref([])
  const iLogList = ref([])

  // IAM Logs
  const fetchInterfaceLogsByDate = datesDTO => {
    console.log(`use reached: ${JSON.stringify(datesDTO)}`)

    store
      .dispatch('app-activity-logs/fetchIAMLogsByDate', datesDTO)
      .then(res => {
        IAMLogList.value = res
        loadingList.value = false

        return res
      })
      .catch(err => {
        console.log(`Error: ${err}`)
      })
  }

  // IAM Logs
  const fetchIAMLogsByDate = datesDTO => {
    console.log(`use reached: ${JSON.stringify(datesDTO)}`)

    store
      .dispatch('app-activity-logs/fetchIAMLogsByDate', datesDTO)
      .then(res => {
        alert(res)
        IAMLogList.value = res
        loadingList.value = false

        return res
      })
      .catch(err => {
        console.log(`Error: ${err}`)
      })
  }

  const test = () => {
    console.log('test')
  }

  // fetch activity logs
  const fetchActivityLogsByDate = datesDTO => {
    store
      .dispatch('app-activity-logs/fetchActivityLogsByDate', datesDTO)
      .then(res => {
        activityLogList.value = res
        loadingList.value = false
      })
      .catch(err => {
        alert(err)
        errorMessages.value = err
      })
  }

  // revert version from log
  const revertEntity = outgoingDTO => {
    store
      .dispatch('app-activity-logs/revertToReversionEntity', outgoingDTO)
      .then(res => {
        alert(`Reverted status: ${res}`)
      })
      .catch(err => {
        console.log('error :>> ', err.response)
        errorMessages.value = err.response.data.error
      })
  }

  return {
    fetchInterfaceLogsByDate,
    fetchIAMLogsByDate,
    test,
    fetchActivityLogsByDate,
    revertEntity,
    IAMColumns,
    iLogsColumns,
    IAMLogList,
    aLogColumns,
    activityLogList,
    loadingList,
    iLogList,
  }
}
